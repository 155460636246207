export default {
  data() {
    return {
      hoursList: ["12:00am", "12:15am", "12:30am", "12:45am", "1:00am", "1:15am", "1:30am", "1:45am",
        "2:00am", "2:15am", "2:30am", "2:45am", "3:00am", "3:15am", "3:30am", "3:45am", "4:00am",
        "4:15am", "4:30am", "4:45am", "5:00am", "5:15am", "5:30am", "5:45am", "6:00am"]
    };
  },
  methods: {
    toDateStr(date, withTime) {
      var month = "" + (date.getMonth() + 1),
        day = "" + date.getDate(),
        year = date.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

    //   return [year, month, day].join("-");

      return withTime
        ? `${[year, month, day].join("-")} ${date.getHours()}:${date.getMinutes()}`
        : `${[year, month, day].join("-")}`;
    },
    formatDateStr(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days));
    },
    addDays(date, days) {
      return new Date(date.setDate(date.getDate() + days));
    },
    minutesTo24hrStr(minutes) {
      let hours = Math.floor(minutes / 60);
      minutes = `${minutes % 60}`;

      if (minutes.length < 2) minutes = "0" + minutes;
      return `${hours}:${minutes}`;
    },
    minutesTo12hrStr(minutes) {
      let hours = Math.floor(minutes / 60);
      let meridiem = hours < 12 ? "AM" : "PM";
      minutes = `${minutes % 60}`;
      hours = `${hours % 12 || 12}`;

      if (minutes.length < 2) minutes = "0" + minutes;
      if (hours.length < 2) hours = "0" + hours;

      return `${hours}:${minutes} ${meridiem}`;
    },
    diffDaysStr(dateStr1, dateStr2) {
      const date1 = new Date(dateStr1);
      const date2 = new Date(dateStr2);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
  }
};
