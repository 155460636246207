<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" md="6">
            <stats-card
              icon="mdi-poll"
              title="Guests for Today"
              :stats="stats.today"
              color="info"
            />
          </v-col>
          <v-col cols="12" md="6">
            <stats-card
              icon="mdi-poll"
              title="Guests for Month"
              :stats="stats.month"
              color="info"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" class="py-0">
        <v-row>
          <v-col cols="12" md="6">
            <stats-card
              icon="mdi-poll"
              title="Pending"
              :stats="stats.pending"
              color="warning"
            />
          </v-col>
          <v-col cols="12" md="6">
            <stats-card
              icon="mdi-poll"
              title="Confirmed"
              :stats="stats.confirmed"
              color="green"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="12">
        <v-card height="550" class="d-flex align-stretch">
          <v-card-text>
          <booking-calendar></booking-calendar>
          </v-card-text>
        </v-card>
      </v-col>
     </v-row>
  </v-container>
</template>

<script>
import { Firestore } from "../plugins/firebaseInit";
import StatsCard from "../components/dashboard/statsCard";
import BookingCalendar from "../components/dashboard/bookingCalendar";
import dateFormatter from "../mixins/dateFormater.js"
export default {
  name: "Dashboard",
  components: { StatsCard , BookingCalendar },
  mixins: [dateFormatter],
  data() {
    return {
      stats: {
        today: 0,
        month: 0,
        pending: 0,
        confirmed: 0,
      }
    }
  },
  created() {
    let date = new Date();
    let firstDay = this.toDateStr(new Date(date.getFullYear(), date.getMonth(), 1));
    let lastDay = this.toDateStr(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    Firestore.collection(`dailyBookings`)
      .where("date", ">=", firstDay)
      .where("date", "<=", lastDay)
      .onSnapshot(snapshot => {
          snapshot.forEach(doc => {
            let data = doc.data();
            if (this.toDateStr(date) == data.date) {
              this.stats.today = data.confirmed
            }
            this.stats.month = this.stats.month + parseInt(data.confirmed || 0);
            this.stats.pending = this.stats.pending + parseInt(data.bookings || 0);
            this.stats.confirmed = this.stats.confirmed + parseInt(data.confirmed || 0);
          });
      });
  },
};
</script>
