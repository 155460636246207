import firebase from "firebase/app";
import "firebase/firebase-firestore";
import "firebase/firebase-auth";
import "firebase/firebase-analytics";
import firebaseConfig from "./firebaseConfig";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const Firestore = firebaseApp.firestore();
const FireAuth = firebaseApp.auth();
const Analytics = firebaseApp.analytics();

FireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

Firestore.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
Firestore.enablePersistence({ synchronizeTabs: true });

const { Timestamp, GeoPoint, FieldValue } = firebase.firestore;
export { Timestamp, GeoPoint, FieldValue, Firestore, FireAuth, Analytics };
