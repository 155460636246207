import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import { FireAuth } from "../plugins/firebaseInit";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    meta: { requireAuth: true },
    component: Dashboard,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "login", webpackPreload: true */ "../views/Login.vue"
      )
  },
  {
    path: "/pending",
    name: "Pending",
    component: () =>
      import(
        /* webpackChunkName: "pending", webpackPreload: true */ "../views/Pending.vue"
      )
  },
  {
    path: "/cancelled",
    name: "Cancelled",
    component: () =>
      import(
        /* webpackChunkName: "cancelled", webpackPreload: true */ "../views/Cancelled.vue"
      )
  },
  {
    path: "/confirmed",
    name: "Confirmed",
    component: () =>
      import(
        /* webpackChunkName: "confirmed", webpackPreload: true */ "../views/Confirmed.vue"
      )
  },
  {
    path: "/booking-parameters",
    name: "BookingParameters",
    component: () =>
      import(
        /* webpackChunkName: "bookingParams", webpackPreload: true */ "../views/BookingParameters.vue"
      )
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(
        /* webpackChunkName: "services", webpackPreload: true */ "../views/Services.vue"
      )
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      FireAuth.signOut().then(() => {
        next({ name: "Login" });
      });
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    FireAuth.onAuthStateChanged(user => {
      if (user) {
        next();
      } else {
        next({
          path: "/login"
        });
      }
    });
  } else {
    FireAuth.onAuthStateChanged(user => {
      if (user && to.name === "Login") {
        next({
          path: "/"
        });
      } else {
        next();
      }
    });
  }
});

export default router