<template>
  <v-card v-bind="$attrs" class="v-card--material v-card--material_stats pa-3">
    <div class="d-flex grow flex-wrap">
      <v-sheet
        :color="color"
        max-height="70"
        width="auto"
        elevation="6"
        class="text-start v-card--material__heading mb-n6 pa-4"
        dark
      >
        <v-icon size="32" v-text="icon" />
      </v-sheet>
      <div class="text-right">
        <div class="card-title font-weight-light" v-text="title" />
        <h3 class="headline font-weight-light text--primary" v-text="stats" />
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "StatsCard",
  props: {
    title: {
      type: String,
      required: true
    },
    stats: {
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
  }
}
</script>
<style lang="sass" scoped>
.v-card--material
  &__heading
    position: relative
    top: -13px
    transition: .3s ease
    z-index: 1
  &_stats
    display: flex
    flex-wrap: wrap

    > div:first-child
      justify-content: space-between

    .v-card
      border-radius: 4px
      flex: 0 1 auto

    .v-card__text
      display: inline-block
      flex: 1 0 calc(100% - 120px)
      position: absolute
      top: 0
      right: 0
      width: 100%

    .v-card__actions
      flex: 1 0 100%
</style>