
<template>
  <v-app>
    <template v-if="currentUser">
      <v-app-bar app absolute flat color="transparent" id="app-bar"  class="d-print-none">
        <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
          <v-icon v-if="drawer">mdi-dots-vertical</v-icon>

          <v-icon v-else>mdi-view-quilt</v-icon>
        </v-btn>
        <v-toolbar-title
          class="hidden-sm-and-down font-weight-light"
          v-text="$route.name"
        />
        <v-spacer></v-spacer>
        {{ currentUser.displayName }}
      </v-app-bar>
      <v-navigation-drawer
        :value="drawer"
        id="core-navigation-drawer"
        mobile-break-point="960"
        app
        width="260"
        class="d-print-none"
      >
        <template v-slot:img="props">
          <v-img
            gradient="to bottom, #A5D6A7, #388E3C"
            v-bind="props"
          />
        </template>

        <v-divider class="mb-1" />

        <v-list dense nav class="py-0">
          <v-list-item>
            <v-list-item-avatar class="align-self-center" color="white" contain>
              <v-img src="./assets/logo.png" max-height="60" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title" style="line-height: 1.5rem">{{ title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="mb-2" />

        <v-list expand nav rounded light>
          <v-list-item link to="/">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/pending">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pending Bookings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/confirmed">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Confirmed Bookings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/cancelled">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cancelled Bookings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/services">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/booking-parameters">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Booking Parameters</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/users">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/logout">
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </template>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>
<script>
import { FireAuth } from "./plugins/firebaseInit";
import { appConfigs } from "./plugins/appConfigs"
export default {
  name: "App",
  data() {
    return {
      title: "LDNP Admin",
      theme: appConfigs.theme,
      currentUser: null,
    }
  },
  created() {
    FireAuth.onAuthStateChanged(user => {
      this.currentUser = user;
    });
  },
  computed: {
    drawer() {
      return this.$store.state.drawer;
    }
  },
  methods: {
    setDrawer(val) {
      // this.drawer = val;
      this.$store.commit("setDrawer", val);
    },
    canEdit(roleRequired) {
      return this.userClaims && this.userClaims.role <= roleRequired;
    }
  }
}
</script>